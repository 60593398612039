import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { nord } from "react-syntax-highlighter/dist/esm/styles/hljs";
import IDesign from "../../../data/models/IDesign";
import { getAsync, putAsync } from "../../../helpers/asyncFetch";

import useGetData from "../../../hooks/dataFetchers/useGetData";
import Img from "../../../shared/BasicHTML/Img";
import DesignOrderExample from "../../../shared/JSONExamples/DesignOrderExample";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import GridItem from "../../../shared/MaterialWrappers/GridItem";
import TabPanel from "../../../shared/TabPanel";
import DesignPricingTable from "../../Designs/Design/components/DesignPricingTable";
import DesignVariablesTable from "../../Designs/Design/components/DesignVariablesTable";
import Design, { DesignField } from "../../Designs/models/Design";
import DesignPricing from "../../Designs/models/DesignPricing";
import PaginatedOrdersTable from "../../Overview/Orders/PaginatedOrdersTable";
import buildFields from "../helpers/buildFields";
import generateHtmlFile from "../helpers/generateHtmlFile";
import getVars from "../helpers/getVars";
import HtmlVersionHistory from "./components/HtmlVersionHistory";
import IHtmlVersion from "../../../data/models/IHtmlVersion";
import DesignFriendlyName from "../../Designs/components/DesignFriendlyName";

interface IParams {
  designId: string;
}

enum TabStates {
  info = "info",
  pricing = "pricing",
  orderExample = "order",
  orderHistory = "history",
  versionHistory = "versionHistory",
}

const HtmlDesign = () => {
  const [tab, setTab] = useState<TabStates>(TabStates.info);
  const [apiVersion, setApiVersion] = useState("v3");
  const [htmlTab, setHtmlTab] = useState<
    "front" | "frontHtml" | "back" | "backHtml"
  >("front");
  const { designId } = useParams<IParams>();
  const { data: pricing } = useGetData<DesignPricing[]>(
    `api/pricing/${designId}`,
    [],
  );
  const history = useHistory();
  const {
    data: design,
    isLoading,
    updateData: setDesign,
    error,
  } = useGetData<Design>(`/api/html/${designId}`, []);

  function handleDesignVersionChange(newVersion: IHtmlVersion) {
    if (design) {
      if (newVersion) {
        // const versions = versionHistory.sort((a, b) =>
        //   a.htmlVersionID === newVersion.htmlVersionID
        //     ? -1
        //     : moment(b.createdDateTime, "DD-MM-YYYYh:mm").diff(
        //         moment(a.createdDateTime, "DD-MM-YYYYh:mm")
        //       )
        // );

        setDesign({
          ...design,
          currentHtmlVersion: { ...newVersion },

          htmlVersionID: newVersion.htmlVersionID,
        });
      }
    }
  }

  function handleDownload(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    html: string,
    name: string,
  ) {
    e.preventDefault();
    name = `${design?.friendlyName}_${name}`;
    generateHtmlFile(html, name);
  }

  function versionSelector() {
    return (
      <Select
        size="small"
        value={apiVersion}
        onChange={(e) => setApiVersion(e.target.value)}
      >
        <MenuItem value="v3">Direct Mail API v3</MenuItem>
        <MenuItem value="v2">Direct Mail API v2</MenuItem>
      </Select>
    );
  }
  const designVariables = getVars(design?.currentHtmlVersion?.html)?.filter(
    (x) => x !== "QrCode",
  );
  const designVarsBack = getVars(design?.currentHtmlVersion?.htmlBack)?.filter(
    (x) => x !== "QrCode",
  );
  const designFields: DesignField[] = buildFields([
    ...designVariables,
    ...designVarsBack,
  ]);

  async function updateFriendlyName(newName: string) {
    if (!design) return;
    await putAsync(`/designs/${design.designID}/name`, {
      name: newName,
    });
    setDesign({ ...design, friendlyName: newName });
  }
  if (error.hasError && error.statusCode === 404) {
    history.push("/designs/html");
    return <div />;
  }
  return (
    <Box sx={{ pt: 8, pb: 8 }}>
      <LoadingWrapper
        loading={isLoading}
        height={500}
        hasError={error.hasError}
      >
        <Box className="designHeader" sx={{ mb: 4 }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs="auto">
              <Typography variant="h4" component="h1">
                Design #{design?.designID}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              {design?.approvalDate !== null && (
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/place-an-order/${design?.designID}`}
                >
                  Place An Order
                </Button>
              )}

              {/* <Button variant="contained" color="primary">
                Generate Proof
              </Button> */}
            </Grid>
            <GridItem xs="auto">
              <Button
                color="primary"
                component={Link}
                to={`/designs/html/${design?.designID}/${design?.htmlVersionID}/proof`}
              >
                Generate A Proof
              </Button>
            </GridItem>
          </Grid>
        </Box>
        <Box className="designStepper" sx={{ mb: 4 }}>
          <Paper>
            <Tabs value={tab} onChange={(t, tab: TabStates) => setTab(tab)}>
              <Tab value={TabStates.info} label="Design Details" />
              <Tab value={TabStates.orderHistory} label="Order History" />
              <Tab value={TabStates.orderExample} label="Order JSON Example" />

              <Tab value={TabStates.versionHistory} label="Version History" />

              {/* <Tab value={Tab.} label="Proof JSON Example" /> */}
            </Tabs>

            <TabPanel value={TabStates.info} activeTab={tab}>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6}>
                    <Box>
                      <Typography variant="h6" gutterBottom>
                        Preview
                      </Typography>
                      <Select
                        fullWidth
                        value={htmlTab}
                        onChange={(e) =>
                          setHtmlTab(
                            e.target.value as
                              | "front"
                              | "back"
                              | "frontHtml"
                              | "backHtml",
                          )
                        }
                        size="small"
                      >
                        {Boolean(design?.proofFront) && (
                          <MenuItem value="front">
                            {Boolean(design?.currentHtmlVersion?.htmlBack)
                              ? "Front "
                              : ""}
                            Proof
                          </MenuItem>
                        )}
                        {Boolean(design?.currentHtmlVersion?.htmlBack) &&
                          Boolean(design?.proofBack) && (
                            <MenuItem value="back">Back Proof</MenuItem>
                          )}
                        <MenuItem value="frontHtml">
                          {design?.currentHtmlVersion?.htmlBack
                            ? "Front HTML"
                            : "HTML"}
                        </MenuItem>

                        {Boolean(design?.currentHtmlVersion?.htmlBack) && (
                          <MenuItem value="backHtml">Back HTML</MenuItem>
                        )}
                      </Select>
                    </Box>

                    <TabPanel
                      value={"frontHtml"}
                      activeTab={htmlTab}
                      sx={{ p: 0, mb: 3 }}
                    >
                      <SyntaxHighlighter
                        language="handlebars"
                        codeTagProps={{
                          style: {
                            overflowX: "hidden",
                          },
                        }}
                        lineProps={{
                          style: {
                            wordBreak: "break-all",
                            whiteSpace: "pre-wrap",
                          },
                        }}
                        wrapLongLines
                        wrapLines
                        customStyle={{
                          overflowX: "hidden",
                          padding: "16px",
                          maxHeight: "350px",
                          height: "350px",
                        }}
                        style={nord}
                      >
                        {design?.currentHtmlVersion?.html}
                      </SyntaxHighlighter>
                    </TabPanel>
                    <TabPanel
                      value={"front"}
                      activeTab={htmlTab}
                      sx={{ p: 0, mb: 3 }}
                    >
                      <Img src={design?.proofFront} />
                    </TabPanel>
                    <TabPanel
                      value={"back"}
                      activeTab={htmlTab}
                      sx={{ p: 0, mb: 3 }}
                    >
                      <Img src={design?.proofBack} />
                    </TabPanel>
                    {Boolean(design?.currentHtmlVersion?.htmlBack) && (
                      <TabPanel
                        value={"backHtml"}
                        activeTab={htmlTab}
                        sx={{ p: 0, mb: 3 }}
                      >
                        <SyntaxHighlighter
                          language="handlebars"
                          codeTagProps={{
                            style: {
                              overflowX: "hidden",
                            },
                          }}
                          lineProps={{
                            style: {
                              wordBreak: "break-all",
                              whiteSpace: "pre-wrap",
                            },
                          }}
                          wrapLongLines
                          wrapLines
                          customStyle={{
                            overflowX: "hidden",
                            padding: "16px",
                            maxHeight: "350px",
                            height: "350px",
                          }}
                          style={nord}
                        >
                          {design?.currentHtmlVersion?.htmlBack}
                        </SyntaxHighlighter>
                      </TabPanel>
                    )}
                    <Box>
                      <Paper sx={{ p: 2 }}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Grid item xs={"auto"}>
                            Current Version:{" "}
                            {design?.currentHtmlVersion?.versionName}
                          </Grid>
                          <Grid item xs="auto">
                            Published:{" "}
                            {moment(
                              design?.currentHtmlVersion?.publishedDateTime,
                            ).format("MM/DD/YYYY h:m a")}
                            {!design?.isTemplate &&
                              Boolean(design?.bAccountID) && (
                                <Button
                                  color="primary"
                                  variant="outlined"
                                  size="small"
                                  sx={{ ml: 2 }}
                                  component={Link}
                                  to={`/designs/html/${designId}/edit`}
                                >
                                  Edit HTML
                                </Button>
                              )}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Box sx={{ mb: 3 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h5">
                          {design?.friendlyName}
                        </Typography>
                        <DesignFriendlyName
                          designID={design?.designID ?? 0}
                          friendlyName={design?.friendlyName ?? ""}
                          callback={(designID, newName) =>
                            updateFriendlyName(newName)
                          }
                        />
                      </Box>
                    </Box>
                    {designFields.length > 0 && (
                      <Box sx={{ mb: 3 }}>
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="h6" gutterBottom>
                            Variables{" "}
                          </Typography>
                        </Box>
                        <DesignVariablesTable designFields={designFields} />
                      </Box>
                    )}
                    <Box sx={{ mb: 3 }}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" gutterBottom>
                          Resources
                        </Typography>
                      </Box>
                      {design !== undefined &&
                        design.currentHtmlVersion !== undefined && (
                          <React.Fragment>
                            <Box>
                              {Boolean(design.proofFront) &&
                                design.printPDFSource === "HTML-LETTER" && (
                                  <React.Fragment>
                                    <a
                                      href={design.proofFront}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Proof
                                    </a>{" "}
                                    |{" "}
                                  </React.Fragment>
                                )}
                              <a
                                href="#"
                                onClick={(e) =>
                                  design && design.currentHtmlVersion
                                    ? handleDownload(
                                        e,
                                        design.currentHtmlVersion.html,
                                        design.currentHtmlVersion.htmlBack
                                          ? "front"
                                          : "letter",
                                      )
                                    : null
                                }
                              >
                                Download{" "}
                                {Boolean(design?.currentHtmlVersion?.htmlBack)
                                  ? "Front"
                                  : ""}{" "}
                                HTML
                              </a>{" "}
                              {Boolean(design.currentHtmlVersion.htmlBack) && (
                                <React.Fragment>
                                  {" "}
                                  |{" "}
                                  <a
                                    href="#"
                                    onClick={(e) =>
                                      design && design.currentHtmlVersion
                                        ? handleDownload(
                                            e,
                                            design.currentHtmlVersion.htmlBack,
                                            "back",
                                          )
                                        : null
                                    }
                                  >
                                    Download Back HTML
                                  </a>
                                </React.Fragment>
                              )}
                              {Boolean(design.sizeInfo?.guidelinesPDF) &&
                                Boolean(
                                  design.printPDFSource?.includes("PC"),
                                ) && (
                                  <Box sx={{ mt: 2 }}>
                                    <a
                                      href={`/uploads/${design.sizeInfo.guidelinesPDF}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Download Design Guidelines
                                    </a>
                                  </Box>
                                )}
                              {Boolean(
                                design.printPDFSource?.includes("LETTER"),
                              ) && (
                                <Box sx={{ mt: 2 }}>
                                  <Typography
                                    gutterBottom
                                    sx={{ fontWeight: 500 }}
                                  >
                                    Envelope Guidelines
                                  </Typography>
                                  <a
                                    href={`/assets/pdf/HTML-DWL_GUIDELINES.pdf`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Download Double Window Envelope
                                  </a>{" "}
                                  |{" "}
                                  <a
                                    href={`/assets/pdf/HTML-FWL_GUIDELINES.pdf`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Download Full Window Envelope
                                  </a>{" "}
                                  |
                                  <a
                                    href={`/assets/pdf/HTML-SWL_GUIDELINES.pdf`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Download Single Window Envelope
                                  </a>
                                </Box>
                              )}
                              {Boolean(design.sizeInfo?.width) &&
                                Boolean(design.sizeInfo.height) && (
                                  <Box sx={{ mt: 2 }}>
                                    Design Size: {design.sizeInfo.height}&quot;
                                    x {design.sizeInfo.width}&quot;
                                  </Box>
                                )}
                            </Box>
                          </React.Fragment>
                        )}
                    </Box>
                    {!design?.printPDFSource
                      ?.toLowerCase()
                      .includes("letter") && (
                      <Box>
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="h6" gutterBottom>
                            Pricing{" "}
                          </Typography>
                        </Box>
                        {pricing &&
                          pricing.length > 0 &&
                          design &&
                          design.mailClasses &&
                          pricing
                            .reduce(
                              (prev, curr) => {
                                const index = prev.findIndex(
                                  (x) => x.mailClass === curr.mailClass,
                                );
                                if (index === -1) {
                                  prev.push({
                                    mailClass: curr.mailClass,
                                    pricing: [curr],
                                  });
                                } else {
                                  prev[index].pricing.push(curr);
                                }
                                return prev;
                              },
                              [] as {
                                mailClass: string;
                                pricing: DesignPricing[];
                              }[],
                            )
                            .map((mailClass) => (
                              <Box key={mailClass.mailClass} sx={{ mb: 3 }}>
                                <DesignPricingTable
                                  mailClass={mailClass.mailClass}
                                  pricing={mailClass.pricing}
                                />
                              </Box>
                            ))}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value={TabStates.orderHistory} activeTab={tab}>
              {/* <OrdersTable designID={design.designID} />; */}

              <PaginatedOrdersTable designID={design?.designID} />
            </TabPanel>
            <TabPanel value={TabStates.orderExample} activeTab={tab}>
              <Box>
                {versionSelector()}
                {design && (
                  <DesignOrderExample
                    design={{ ...design, designFields: designFields }}
                    designType={design.printPDFSource}
                    isHTML={true}
                    version={apiVersion}
                  />
                )}
              </Box>
            </TabPanel>
            <TabPanel value={TabStates.versionHistory} activeTab={tab}>
              {design && (
                <HtmlVersionHistory
                  designID={design.designID}
                  updateDesign={handleDesignVersionChange}
                  currentVersionId={design?.htmlVersionID ?? ""}
                />
              )}
            </TabPanel>
            {/* <TabPanel value={tabs.proof} activeTab={activeTab}>
              <DesignProofExample design={design} />
            </TabPanel> */}
          </Paper>
        </Box>
      </LoadingWrapper>
    </Box>
  );
};

export default HtmlDesign;
