import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import useGetData from "../../hooks/dataFetchers/useGetData";
import IPosition from "../features/Canvas/models/IPosition";
import { CanvasContext } from "../state/contexts/CanvasContext";
import IAssetLibrary from "../state/models/ICustomerImageLibrary";
import { fabric } from "fabric";
import {
  insertNewAsset,
  loadLibrary,
  selectAssetLibrary,
} from "../state/slices/assetLibrary";
import { useDesignerDispatch, useDesignerSelector } from "../state/store";
import useHistory from "./useHistory";
import useLayers from "./useLayers";
import generateGuid from "../../helpers/generateGuid";
import { getAsync, postAsync } from "../../helpers/asyncFetch";
import isAdminUser from "../../helpers/isAdminUser";
import { selectDesignInformation } from "../state/slices/designInformation";
import IAsset from "../../data/models/IAsset";
import { createBleedClipPath } from "../features/Canvas/functions/createBackgroundClipPath";
import { CanvasObject } from "../state/contexts/PagesContext";
import dynamicImagePlaceholder from "../features/Canvas/svg/dynamicImagePlaceholder";
import { Image, Object as IObject } from "fabric/fabric-impl";
import { setCurrentTool } from "../state/slices/toolSettings";
import { Tool } from "../state/models/ICanvasTool";
import IMAGEWRAPPER from "../constants/IMAGEWRAPPER";
import placeholder from "../../assets/images/dynamic-image-placeholder.png";
import initializeFrame from "../features/ImageFrames/initializeFrame";
import {
  getBleedElement,
  getClampedPosition,
  outsideBleed,
} from "../../helpers/canvas";
const useAssetLibrary = (disablePosition = false) => {
  const assetLibrary = useDesignerSelector(selectAssetLibrary);
  const canvas = useContext(CanvasContext);
  const { designID, bAccountID } = useDesignerSelector(selectDesignInformation);

  const isAdmin = !bAccountID && isAdminUser();
  const { createLayer } = useLayers();

  const [mousePos, setMousePos] = useState<IPosition>({ x: 0, y: 0 });

  const dispatch = useDesignerDispatch();

  async function onDropFile(file: File, disableAddToCanvas?: boolean) {
    const asset = await uploadAsset(file);

    if (asset) {
      dispatch(insertNewAsset(asset));
      if (!disableAddToCanvas) addAssetToCanvas(asset);
    }

    return asset;
  }

  async function loadAssets() {
    if (assetLibrary.isLoaded) return;
    const assets = await getAsync<IAssetLibrary>(
      `/assets?designID=${designID}`,
    );
    if (assets) {
      dispatch(loadLibrary(assets));
    }
  }

  async function uploadAsset(file: File) {
    return await postAsync<IAsset>(
      `/assets`,
      {
        file: file,
        fileName: file.name,
        isAdmin: isAdmin.toString(),
        designID: isAdmin ? designID?.toString() ?? "null" : "null",
        isShape: file.type?.includes("svg"),
      },
      true,
    );
  }

  async function addAssetToCanvas(asset: IAsset) {
    if (!canvas) return;
    const bleed = getBleedElement(canvas);
    if (!bleed) return;

    const useBackground = mousePos.x === 0 && mousePos.y === 0;
    let pos = mousePos;

    if (useBackground) {
      const background = canvas._objects.find((x) => x.name === "background");
      if (background) {
        const center = background.getCenterPoint();
        pos = { x: center.x, y: center.y };
      }
    }
    const [xOutside, yOutside] = outsideBleed(bleed, pos);
    if (xOutside || yOutside) {
      pos = getClampedPosition(bleed, pos);
    }

    if (asset.extension.includes("svg")) {
      const svg = fabric.loadSVGFromURL(
        asset.url,
        (svg, options) => {
          const img = fabric.util.groupSVGElements(svg, options);

          const imageWidth = img.getScaledWidth();
          const imageHeight = img.getScaledHeight();

          let [xOffset, yOffset] = [0, 0];
          if (xOutside && !!bleed.left && pos.x > bleed.left) {
            xOffset = imageWidth;
          }
          if (yOutside && !!bleed.top && pos.y > bleed.top) {
            yOffset = imageHeight;
          }

          img
            .set({
              left: xOutside ? pos.x - xOffset : pos.x - imageWidth / 2,
              top: yOutside ? pos.y - yOffset : pos.y - imageHeight / 2,
              perPixelTargetFind: true,
              clipPath: createBleedClipPath(canvas),
            })
            .setCoords();

          img.name = generateGuid();
          img.clipPath = createBleedClipPath(canvas);

          canvas.add(img);
          canvas.setActiveObject(img);
          canvas.renderAll();
          createLayer(img.name, asset.name);
        },
        undefined,
        { crossOrigin: "anonymous" },
      );
    } else {
      const image = fabric.Image.fromURL(
        asset.url,
        (img) => {
          img.set({
            originX: "center",
            originY: "center",
            crossOrigin: "Anonymous",
          });
          const group = new fabric.Group(
            [
              img,
              new fabric.Rect({
                width: img.width,
                height: img.height,
                strokeUniform: true,
                name: IMAGEWRAPPER,
                stroke: "#000000",
                strokeWidth: 0,
                fill: "",
                originX: "center",
                originY: "center",
                perPixelTargetFind: true,
              }),
            ],
            { originX: "center", originY: "center", perPixelTargetFind: true },
          );

          let [xOffset, yOffset] = [0, 0];
          if (xOutside && !!bleed.left && !!img.width && pos.x > bleed.left) {
            xOffset = img.width;
          }
          if (yOutside && !!bleed.top && !!img.height && pos.y > bleed.top) {
            yOffset = img.height;
          }

          group.left = xOutside
            ? pos.x - xOffset + (img.width ?? 0) / 2
            : pos.x;
          group.top = yOutside
            ? pos.y - yOffset + (img.height ?? 0) / 2
            : pos.y;

          group.name = "image-" + generateGuid();
          group.clipPath = createBleedClipPath(canvas);
          canvas.add(group);
          canvas.setActiveObject(group);

          createLayer(group.name, asset.name);
        },
        { crossOrigin: "Anonymous" },
      );
    }
    dispatch(setCurrentTool(Tool.select));
  }

  function onDropLibraryAsset(asset: IAsset) {
    addAssetToCanvas(asset);
  }

  function addVariableImageToCanvas() {
    if (!canvas) return;

    const bleed = getBleedElement(canvas);
    if (!bleed) return;

    let pos = mousePos;

    const [xOutside, yOutside] = outsideBleed(bleed, mousePos);
    if (xOutside || yOutside) {
      pos = getClampedPosition(bleed, pos);
    }

    const image = fabric.Image.fromURL(placeholder, (img) => {
      img.set({ originX: "center", originY: "center" });
      const group = new fabric.Group(
        [
          img,
          new fabric.Rect({
            width: img.width,
            height: img.height,
            strokeUniform: true,
            name: IMAGEWRAPPER,
            stroke: "#000000",
            strokeWidth: 0,
            fill: "rgba(0,0,0,0)",
            originX: "center",
            originY: "center",
          }),
        ],
        { originX: "center", originY: "center" },
      );
      img.name = "DynamicImage";

      let [xOffset, yOffset] = [0, 0];
      if (xOutside && !!bleed.left && !!img.width && pos.x > bleed.left) {
        xOffset = img.width;
      }
      if (yOutside && !!bleed.top && !!img.height && pos.y > bleed.top) {
        yOffset = img.height;
      }

      group.left = xOutside ? pos.x - xOffset + (img.width ?? 0) / 2 : pos.x;
      group.top = yOutside ? pos.y - yOffset + (img.height ?? 0) / 2 : pos.y;

      group.name = "image-" + generateGuid();
      group.clipPath = createBleedClipPath(canvas);
      canvas.add(group);
      canvas.setActiveObject(group);
      initializeFrame(group);
      dispatch(setCurrentTool(Tool.select));
      createLayer(group.name, "{{DynamicImage}}");
    });
  }

  function initializeMousePos() {
    if (!canvas || disablePosition) return;
    canvas.on("dragover", function (e) {
      const pos = canvas.getPointer(e.e);
      setMousePos({ x: pos.x, y: pos.y });
    });
  }

  useEffect(initializeMousePos, [canvas]);

  useEffect(() => {
    loadAssets();
  }, []);

  return {
    assetLibrary,
    onDropFile,
    onDropLibraryAsset,
    addVariableImageToCanvas,
  };
};

export default useAssetLibrary;
