import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import LoadingButton from "../../../shared/LoadingButton";
import { postAsync } from "../../../helpers/asyncFetch";
import { useHistory } from "react-router";
import Design from "../models/Design";
import DesignerSizes from "../../../data/models/DesignerSizes";

const NewDesignerDesign = () => {
  const [newDesignerDesign, setNewDesignerDesign] = useState({
    size: "46",
    name: "",
    isTemplate: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();

  async function handleAddClick() {
    setIsLoading(true);
    const design = await postAsync<{ design: Design }>(
      `/designer`,
      newDesignerDesign,
    );
    if (design && design.design) {
      history.push(`/designs/designer/${design.design.designID}/edit`);
    } else {
      setIsLoading(false);
      setError(true);
    }
  }

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" gutterBottom>
          Design Your Own Postcard
        </Typography>
        <Typography variant="body1" gutterBottom>
          Enter a name and select a size for your custom postcard to get
          started.
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            name="name"
            label="Name"
            value={newDesignerDesign.name}
            onChange={(e) =>
              setNewDesignerDesign({
                ...newDesignerDesign,
                name: e.target.value,
              })
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel>Size</InputLabel>
            <Select
              label="Size"
              name="size"
              value={newDesignerDesign.size}
              onChange={(e) =>
                setNewDesignerDesign({
                  ...newDesignerDesign,
                  size: e.target.value,
                })
              }
            >
              <MenuItem value={DesignerSizes.FOUR_BY_SIX}>
                4.25&quot; x 6&quot;
              </MenuItem>
              <MenuItem value={DesignerSizes.SIX_BY_EIGHT}>
                6&quot; x 8.5&quot;
              </MenuItem>
              <MenuItem value={DesignerSizes.SIX_BY_ELEVEN}>
                6&quot; x 11&quot;
              </MenuItem>
              <MenuItem value={DesignerSizes.LETTER}>Letter</MenuItem>
              <MenuItem value={DesignerSizes.BROCHURE}>Brochure</MenuItem>
              <MenuItem value={DesignerSizes.SNAP}>Snap Apart</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <LoadingButton
              color="primary"
              loading={isLoading}
              onClick={handleAddClick}
              disabled={
                !newDesignerDesign.name ||
                !newDesignerDesign.name.trim() ||
                isLoading
              }
            >
              Create Design
            </LoadingButton>

            {error && (
              <Box>
                <FormHelperText error>
                  Something went wrong, please try again later.{" "}
                </FormHelperText>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewDesignerDesign;
