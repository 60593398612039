import { Group, IText, Object as IObject, Path } from "fabric/fabric-impl";
import React, { createContext, useReducer } from "react";
import { ILayersCollection } from "../models/ILayers";
import { IPage } from "./PagesContext";
import { LetterFoldTypes } from "../slices/letterSettings";

export interface ISaveData {
  pages: IPage[];
  layers: ILayersCollection[];
  envelopeType?: string;
  foldType?: LetterFoldTypes;
  width?: number;
  height?: number;
  margins?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
}

export const SaveDataContext = createContext<ISaveData | null>(null);

export const SaveDataDispatchContext = createContext<React.Dispatch<ISaveData>>(
  {} as React.Dispatch<ISaveData>,
);
const intialState = null;

function saveDataReducer(state: ISaveData | null, data: ISaveData) {
  if (data) return data;
  return state;
}

interface IProviderProps {
  children?: React.ReactFragment;
}

const SaveDataProvider = ({ children }: IProviderProps) => {
  const [pages, dispatch] = useReducer(saveDataReducer, intialState);

  return (
    <SaveDataContext.Provider value={pages}>
      <SaveDataDispatchContext.Provider value={dispatch}>
        {children}
      </SaveDataDispatchContext.Provider>
    </SaveDataContext.Provider>
  );
};

export default SaveDataProvider;
