import { IText } from "fabric/fabric-impl";
import { useEffect, useRef, useState } from "react";
import WebFont from "webfontloader";
import { ISaveData } from "../../state/contexts/SaveDataContext";
import { initializeFonts } from "../../state/slices/fontManager";
import { useDesignerDispatch } from "../../state/store";
import googleFontsService from "./googleFontsService/googleFontsService";
import nonGoogleFontSafeList from "./nonGoogleFontSafeList";

const useLoadCanvasFonts = (defaultCanvasData?: string) => {
  const [loaded, setLoaded] = useState(false);
  const fontsLoadedRef = useRef<string[]>([]);
  const dispatch = useDesignerDispatch();
  async function init() {
    if (loaded) return;

    const saveData: ISaveData = defaultCanvasData
      ? JSON.parse(defaultCanvasData)
      : undefined;

    const fonts = await googleFontsService.getFontsAsync();

    if (saveData) {
      const fontsInUse = googleFontsService
        .getFontsInDocument(saveData)
        .filter((x) => !nonGoogleFontSafeList.includes(x));
      const fontsWithWeights = fonts.filter(
        (x) => fontsInUse.includes(x.family) || x.family === "Roboto",
      );

      fonts.forEach((font) => {
        if (fontsInUse.includes(font.family)) {
          font.loaded = true;
        }
      });

      const fontLoadStrings = fontsWithWeights.map(
        (x) => `${x.family}:${x.variants.map((v, i) => v).join(",")}`,
      );

      WebFont.load({
        google: {
          families: fontLoadStrings,
        },
        typekit: {
          id: "bdj4yir",
        },
        fontactive: (f, g) => {
          if (!fontsLoadedRef.current.includes(f)) {
            fontsLoadedRef.current.push(f);
          }
          if (fontsLoadedRef.current.length >= fontsInUse.length) {
            setTimeout(() => {
              setLoaded(true);
            }, 500);
          }
        },
      });
    } else {
      WebFont.load({
        google: {
          families: ["Roboto"],
        },
        typekit: {
          id: "bdj4yir",
        },
        fontactive: () => {
          setTimeout(() => {
            setLoaded(true);
          }, 100);
        },
      });
    }

    dispatch(initializeFonts(fonts));
  }

  useEffect(() => {
    init();
  }, []);

  return loaded;
};

export default useLoadCanvasFonts;
