import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import useSaveData from "../../../hooks/useSaveData";
import DesignerButton from "../shared/DesignerButton";
import Modal, { ModalActions, ModalBody, ModalTitle } from "../shared/Modal";
import tw, { css } from "twin.macro";
import { useDesignerDispatch, useDesignerSelector } from "../../../state/store";
import { selectDesignInformation } from "../../../state/slices/designInformation";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import { ISaveData } from "../../../state/contexts/SaveDataContext";
import loadSavedCanvasData from "../../Canvas/functions/loadSavedCanvasData";
import { loadLayers } from "../../../state/slices/layers";
import { SelectedPageDispatchContext } from "../../../state/contexts/SelectedPageContext";
import Sizes from "../../../constants/Sizes";
import DesignerSizes from "../../../../data/models/DesignerSizes";

interface IRevertDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const RevertDialog = ({ isOpen, onClose }: IRevertDialogProps) => {
  const designInformation = useDesignerSelector(selectDesignInformation);
  const canvas = useContext(CanvasContext);
  const dispatch = useDesignerDispatch();
  const selectedPageDispatch = useContext(SelectedPageDispatchContext);
  async function revertChanges() {
    if (
      designInformation.initialJson &&
      canvas &&
      designInformation.canvasSettings
    ) {
      const saveData: ISaveData = JSON.parse(designInformation.initialJson);
      canvas.remove(...canvas.getObjects());
      const overlay =
        Sizes[
          designInformation.canvasSettings?.overlay ?? DesignerSizes.FOUR_BY_SIX
        ];
      const pages = await loadSavedCanvasData(
        canvas,
        saveData.pages,
        designInformation.canvasSettings?.overlay,
        designInformation.canvasSettings?.width,
        designInformation.canvasSettings.height,
        saveData.layers,
        overlay,
        designInformation.productType,
      );
      dispatch(loadLayers(saveData.layers));
      selectedPageDispatch({
        type: "page",
        payload: pages[0].name,
      });
      onClose();
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalTitle>Are You Sure?</ModalTitle>
      <ModalBody>
        This action will undo all changes from your current session.
      </ModalBody>
      <ModalActions>
        <DesignerButton css={tw`mr-2`} onClick={revertChanges}>
          Confirm
        </DesignerButton>
        <DesignerButton onClick={onClose}>Cancel</DesignerButton>
        {/* <DesignerButton onClick={saveAndGenerateProof} disabled={disabled}>
          Save and Generate A Proof
        </DesignerButton> */}
      </ModalActions>
    </Modal>
  );
};

export default RevertDialog;
