import RESOLUTION from "../../../constants/RESOLUTION";
import { ISafeAreaObject } from "../../../constants/Sizes";
import { Canvas } from "fabric/fabric-impl";
import { fabric } from "fabric";
import DesignerProductTypes from "../../../../data/models/DesignerProductTypes";
import { LetterFoldTypes } from "../../../state/slices/letterSettings";
import insertOverlayObjects from "./insertOverlayObjects";
import { createBleedClipPath } from "./createBackgroundClipPath";
import { current } from "@reduxjs/toolkit";
export default function insertBackground(
  canvas: Canvas,
  sizeInformation: ISafeAreaObject,
  page: number,
  productType: DesignerProductTypes,
  envelopeType?: string,
  foldType?: LetterFoldTypes,
) {
  const existingBleed = canvas.getObjects().find((x) => x.name === "bleed");

  if (existingBleed) {
    const overlayObjects = canvas
      .getObjects()
      .filter(
        (x) =>
          x.name === "overlay" || x.name === "background" || x.name === "bleed",
      );
    canvas.remove(...overlayObjects);
  }

  const currentPage = sizeInformation.pages?.find((x) => x.pageNumber === page);
  const width = currentPage?.width ?? sizeInformation.width;
  const height = currentPage?.height ?? sizeInformation.height;
  const safeAreaInfo = currentPage?.safeArea ?? sizeInformation.safeArea;
  const bleedWidth = currentPage?.bleed?.width ?? sizeInformation.bleed;
  const bleedHeight = currentPage?.bleed?.height ?? sizeInformation.bleed;
  // create the white background rectangle

  const background = new fabric.Rect({
    width: (width - (bleedWidth ?? 0)) * RESOLUTION,
    height: (height - (bleedHeight ?? 0)) * RESOLUTION,
    fill: "white",
    strokeWidth: 0,
    name: "background",
    stroke: "black",
    selectable: false,
    lockMovementX: true,
    lockMovementY: true,
    perPixelTargetFind: true,
    strokeUniform: true,
  });

  const bleed = new fabric.Rect({
    width: width * RESOLUTION - 2,
    height: height * RESOLUTION - 2,
    stroke: "blue",
    strokeWidth: 2,
    name: "bleed",
    selectable: false,
    lockMovementX: true,
    strokeUniform: true,
    lockMovementY: true,
    fill: "rgba(0,0,0, 0)",
    perPixelTargetFind: true,
  });

  const safeArea = new fabric.Rect({
    width: safeAreaInfo.width * RESOLUTION - 2,
    height: safeAreaInfo.height * RESOLUTION - 2,
    stroke: "green",
    strokeUniform: true,
    strokeWidth: 2,
    strokeDashArray: [8, 8],
    name: "overlay",
    selectable: false,
    lockMovementX: true,
    lockMovementY: true,
    fill: "rgba(0,0,0, 0)",
    perPixelTargetFind: true,
  });

  canvas.insertAt(background, 0, false);
  canvas.add(bleed);
  canvas.add(safeArea);
  canvas.centerObject(background);
  canvas.centerObject(bleed);
  canvas.centerObject(safeArea);

  if (currentPage?.safeArea?.topOffset) {
    safeArea.top = safeArea.top! + currentPage.safeArea.topOffset * RESOLUTION;
  }
  if (currentPage?.safeArea?.leftOffset) {
    safeArea.left =
      safeArea.left! + currentPage.safeArea.leftOffset * RESOLUTION;
  }
  if (currentPage?.bleed?.topOffset) {
    background.top = background.top! + currentPage.bleed.topOffset * RESOLUTION;
  }
  if (currentPage?.bleed?.leftOffset) {
    background.left =
      background.left! + currentPage.bleed.leftOffset * RESOLUTION;
  }

  if (sizeInformation.bleed) {
    const bleedLabel = new fabric.Text("Bleed", {
      fill: "blue",
      originX: "center",
      originY: "center",
      left: bleed.getCenterPoint().x,
      top: bleed.getCenterPoint().y - bleed.getScaledHeight() / 2 - 20,
      name: "overlay",
      fontSize: 26,
      evented: false,
      selectable: false,
      fontFamily: "Roboto",
    });
    canvas.insertAt(bleedLabel, 1, false);
  }
  const safeAreaLabelTop =
    currentPage?.safeArea?.topOffset && currentPage.safeArea.topOffset < 0
      ? safeArea.getCenterPoint().y + safeArea.getScaledHeight() / 2 + 20
      : safeArea.getCenterPoint().y - safeArea.getScaledHeight() / 2 - 20;

  const safeAreaLabel = new fabric.Text("Safe Area", {
    fill: "green",
    originX: "center",
    originY: "center",
    left: safeArea.getCenterPoint().x,
    top: safeAreaLabelTop,
    name: "overlay",
    fontSize: 26,
    evented: false,
    selectable: false,
    fontFamily: "Roboto",
  });
  const bleedLabel = new fabric.Text("Bleed", {
    fill: "blue",
    originX: "center",
    originY: "center",
    left: bleed.getCenterPoint().x,
    top: bleed.getCenterPoint().y - bleed.getScaledHeight() / 2 - 20,
    name: "overlay",
    fontSize: 26,
    evented: false,
    selectable: false,
    fontFamily: "Roboto",
  });

  canvas.insertAt(safeAreaLabel, 3, false);
  if (sizeInformation.bleed || currentPage?.bleed?.height) {
    canvas.insertAt(bleedLabel, 4, false);
  }
  if (currentPage?.foldLines) {
    for (let i = 0; i < currentPage.foldLines.number; i++) {
      const top =
        currentPage.foldLines.direction === "horizontal"
          ? bleed.getScaledHeight() / (currentPage.foldLines.number * (i + 2)) +
            bleed.top!
          : bleed.top!;
      const left =
        currentPage.foldLines.direction === "vertical"
          ? bleed.getScaledWidth() / (currentPage.foldLines.number * (i + 2)) +
            bleed.left!
          : bleed.left!;
      console.log(top, left);
      const foldLine = new fabric.Rect({
        width:
          currentPage.foldLines.direction === "horizontal"
            ? bleed.getScaledWidth()
            : 1,
        strokeWidth: 2,

        stroke: "purple",
        height:
          currentPage.foldLines.direction === "vertical"
            ? bleed.getScaledHeight()
            : 1,

        top: top,
        left: left,
        fill: "purple",
        selectable: false,

        name: "overlay",
        //@ts-ignore
        __foldLine: true,
      });
      const foldLineText = new fabric.Text(
        i === 0 && currentPage.foldLines.number === 1
          ? "Fold"
          : `Fold ${i + 1}`,
        {
          fill: "purple",
          originX: "center",
          originY: "center",
          left:
            foldLine.height! > 1
              ? foldLine.getCenterPoint().x - 20
              : foldLine.getCenterPoint().x,
          top:
            foldLine.width! > 1
              ? foldLine.getCenterPoint().y -
                foldLine.getScaledHeight() / 2 +
                20
              : foldLine.getCenterPoint().y -
                foldLine.getScaledHeight() / 2 +
                110,
          name: "overlay",
          fontSize: 26,
          evented: false,
          fontFamily: "Roboto",
          //@ts-ignore
          __foldLine: true,
        },
      );
      canvas.insertAt(foldLine, 5, false);
      canvas.insertAt(foldLineText, 6, false);
    }
  }
  canvas.renderAll();
  insertOverlayObjects(
    canvas,
    sizeInformation,
    page,
    productType,
    envelopeType,
    foldType,
  );
  // if the size has changed, we need to update the clip paths of every object
  if (
    existingBleed &&
    (existingBleed.width !== bleed.width ||
      existingBleed.height !== bleed.height)
  ) {
    const objectsWithBleedClip = canvas._objects.filter(
      (x) =>
        x.clipPath &&
        x.clipPath.width &&
        x.clipPath.width === existingBleed.width &&
        x.clipPath.height &&
        x.clipPath.height === existingBleed.height,
    );

    if (objectsWithBleedClip.length) {
      objectsWithBleedClip.forEach((obj) => {
        if (
          obj.name !== "bleed" &&
          obj.name !== "background" &&
          obj.name !== "overlay"
        ) {
          obj.clipPath = createBleedClipPath(canvas);
        }
      });
    }
  }
}
