enum DesignerProductTypes {
  LETTER = "LETTER",
  BROCHURE = "BRO",
  POSTCARD = "PC",
  SNAP = "SNP",
  GREETING_CARD = "GC",
}
export default DesignerProductTypes;

export function getProductType(printPdfSource: string) {
  const normalized = printPdfSource.toLowerCase().replace("designer-", "");

  switch (normalized) {
    case "letter":
      return DesignerProductTypes.LETTER;
    case "bro":
      return DesignerProductTypes.BROCHURE;
    case "pc":
      return DesignerProductTypes.POSTCARD;
    case "snp":
      return DesignerProductTypes.SNAP;
    case "gc":
      return DesignerProductTypes.GREETING_CARD;
    default:
      return DesignerProductTypes.POSTCARD;
  }
}
